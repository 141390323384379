import { atom } from 'jotai';

import type { UserRole } from '~/types/user';

type UserDataType =
  | {
      type: 'anonymous';
    }
  | {
      type: 'user';
      userRole: UserRole;
    };

export const userDataAtom = atom<UserDataType | undefined>(undefined);
